import logo from './logo.svg';
import './App.css';
import react from "react";

class App extends react.Component {

  constructor(props) {
    super(props);
    this.updateText = this.updateText.bind(this)
    this.generateHTML = this.generateHTML.bind(this)
    this.state ={
      title: undefined,
      desc: undefined,
      result: undefined
    }
  }

  html = `<!DOCTYPE HTML>
<!-- Frankeneverything Description Page by Techman -->
<html>
\t<head>
\t\t<meta charset="utf-8">
\t\t<meta name="viewport" content="width=device-width,initial-scale=1" />
\t\t<title>Frankeneverything</title>
\t\t<style>
\t\t\t/* General Rules by Techman */
\t\t\t.center {
\t\t\t\ttext-align: center;
\t\t\t}
\t\t\timg.center{
\t\t\t\tdisplay: block;
\t\t\t\tmargin-left: auto;
\t\t\t\tmargin-right: auto;
\t\t\t}
\t\t\t.left {
\t\t\t\ttext-align: left;
\t\t\t\tfloat: left;
\t\t\t}
\t\t\t.right {
\t\t\t\ttext-align: right;
\t\t\t\tfloat: right;
\t\t\t}
\t\t\t.title {
\t\t\t\ttext-align: center;
\t\t\t}
\t\t\t.inline-block {
\t\t\t\tdisplay: inline-block;
\t\t\t}
\t\t\t/*Website-Specific Rules*/
\t\t\t* {
\t\t\t\tbackground-color: white;
\t\t\t\tcolor: black;
\t\t\t\tfont-family: Arial, sans-serif;
\t\t\t\tposition: relative;
\t\t\t\tmargin: 5px;
\t\t\t}
\t\t\timg {
\t\t\t\twidth: auto;
\t\t\t\theight: auto;
\t\t\t\tmax-width: 100%;
\t\t\t\tmax-height: 100%;
\t\t\t}
\t\t\t.color, strong, h1, h2{
\t\t\t\tcolor: #2bfb14;
\t\t\t}
\t\t\timg.profile {
\t\t\t\tborder: 3px solid black;
\t\t\t\tborder-radius: 50%;
\t\t\t\twidth: 150px;
\t\t\t\theight: 150px;
\t\t\t}
\t\t\thr {
\t\t\t\ttop: 10px;
\t\t\t\twidth: 100%;
\t\t\t}
\t\t</style>
\t</head>

\t<body>
\t\t<!--Item Title-->
\t\t<h1>%%title%%</h1><br>
\t\t
\t\t<!-- Item Description -->
\t\t<p>%%desc%%</p>
\t\t
\t\t<footer>
\t\t\t<br><hr /><br />
\t\t\t<a href="https://ebay.com/usr/frankeneverything" target="_blank"><img class="left profile" src="https://frankeneverything.com/frankeneverything.png"/></a>
\t\t\t<div class="right">
\t\t\t\t<br />
\t\t\t\t<h1>Frankeneverything</h1>
\t\t\t\t<h3><a href="https://www.ebay.com/usr/frankeneverything" target="_blank">Check Out Our Other Items</a></h3>
\t\t\t</div>
\t\t</footer>
\t</body>
</html>`

    updateText(item, id) {

      this.setState({[item]: document.getElementById(id).value !== "" ? document.getElementById(id).value : " "})
      let MaxHeight = 1000
      let counter =0
      let textarea = document.getElementById(id)
      let textareaRows = textarea.value.split("\n");
      if(textareaRows[0] != "undefined" && textareaRows.length < MaxHeight) counter = textareaRows.length;
      else if(textareaRows.length >= MaxHeight) counter = MaxHeight;
      else counter = 1;
      textarea.rows = counter;
    }

    generateHTML() {
    const newSite = this.html.replace(/%%title%%/g, this.state.title).replace(/%%desc%%/g, `${this.state.title}. ${this.state.desc}`)
      this.setState({result: newSite})

      // Copy the text inside the text field
      navigator.clipboard.writeText(newSite);
    }

  render() {
    return (
        <div className="App w-screen min-h-screen p-12">
          <p className={"text-3xl font-bolder border-b-2 border-b-blue-400 mb-12"}>frankeneverything Desc Gen v1.0</p>
          <textarea type="text" id={"title"} placeholder={"title"} className={"w-full min-h-24 resize-y block text-black border-b-2 border-b-blue-400 h-fit"} value={this.state.title ? this.state.title : "title"} onChange={() => this.updateText("title", "title")}></textarea>
          <textarea type="text" id={"desc"} placeholder={"desc"} className={"w-full min-h-52 resize-y block border-b-2 border-b-blue-400 h-fit"} value={this.state.desc ? this.state.desc : "desc"} onChange={() => this.updateText("desc", "desc")}></textarea>

          <button onClick={this.generateHTML} className={"mb-10 mt-10 text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"}>Generate</button>




            <div>
                <textarea type="text" id={"result"} placeholder={"result"} className={"w-full h-fit resize-y block text-black border-b-2 border-b-blue-400 font-mono"} value={this.state.result ? this.state.result : "result"} onChange={() => this.updateText("result", "result")}></textarea>
            </div>
        </div>
    );
  }
}

export default App;
