import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* techman.dev AUTH Client Snippet v1.0 - Webpack Version */
export let authData = {}
let cookies = {};
if (document.cookie) {
    try {
        const cookieHeader = document.cookie
        for (let i = 0; i < cookieHeader.split(';').length; i++) {
            let nameValue = cookieHeader.split(';')[i].split('=');
            cookies[nameValue[0].trim()] = nameValue[1];
        }
        authData = JSON.parse(decodeURIComponent(cookies.authData))
        console.log(JSON.stringify(authData))
    } catch (err) {
        console.error("[AUTH] authData not present")
    }
}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
